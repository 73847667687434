import React from "react"
import { graphql } from "gatsby"
import Title from "../../components/title"
import Layout from "../../components/layout"
import StyledHero from "../../components/styledHero"
import styles from "../../css/offer.module.css"
import SEO from "../../components/seo"

const Isg = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Leistungsangebot ISG" />
      <StyledHero img={data.isgBcg.childImageSharp.fluid} />
      <section className={styles.offer}>
        <Title title="Leistungsangebot" subtitle="ISG" />
        <div className={styles.offerCenter}>
          <header>
            <a
              href="https://isg-one.com/DE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.partnerLogo}
                src="/isg.png"
                alt="isg Logo"
              />
            </a>
            <h4>
              Pro Bono Leistungsangebot Information Services Group Germany GmbH
              (ISG)
            </h4>
          </header>
          <article className={styles.offerText}>
            <h4>
              "Cost take-out Quick Check" – Kurzfristige Kostensenkungsmaßnahmen
              zur Schaffung von Liquidität
            </h4>
            <p>
              Schaffung von Liquidität durch kurzfristige Realisierung von
              IT-Kosteneinsparungen: Der ISG "Cost take-out Quick Check"
              überprüft in einem pragmatischen Benchmarkingansatz, welche
              IT-Kostensenkungspotenziale in Ihrem Unternehmen bestehen.
              Kurzfristiges IT-Kostensenkungspotenzial lässt sich beispielsweise
              durch die Kündigung nicht mehr benötigter Leistungen wie Software
              oder Cloud-Angebote, durch Vertragsoptimierung zur Vermeidung
              nicht zwingend erforderlicher oder zeitlich aufschiebbarer
              Zahlungen, Vermeidung von Ineffizienzen (ähnliche, aber
              vermeidbare Services), oder die kurzfristige Neuverhandlung von
              Verträgen und Optimierung des Nutzungsverhaltens erreichen. Unser
              kostenfreies Angebot schafft innerhalb weniger Tage Transparenz zu
              kurzfristig realisierbarem IT-Kostensenkungspotenzial.
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>
              Business Continuity Quick Check zur Absicherung des laufenden
              IT-Betriebes
            </h4>
            <p>
              Der für IT-Anwender kostenfreie Business Continuity Check von ISG
              überprüft anhand der drei Dimensionen Incident Management,
              Resources Management und Liability Management, welcher
              Handlungsbedarf in Ihrem Unternehmen zur Absicherung von Business
              Continuity besteht. Mithilfe unserer Entscheidungskriterien können
              Sie für Ihre kritischen Geschäftsprozesse notwendige
              Handlungsbedarfe identifizieren, so dass entsprechende
              Gegenmaßnahmen zur Absicherung Ihrer Geschäftstätigkeit ergriffen
              werden können.
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>
              Robotic Process Automation (RPA) Pilotierung – Kurzfristige
              Automatisierung kritischer Geschäftsprozesse
            </h4>
            <p>
              Die Automatisierung standardisierter Geschäftsprozesse ist ein
              schnelles und einfaches Mittel, um Ihren Ressourceneinsatz zu
              optimieren und um kurzfristige Nachfrageschwankungen bestmöglich
              abzufedern. Unsere kostenfreie RPA Pilotierung identifiziert
              realisierbares und kurzfristig zugängliches
              Automatisierungspotenzial in Ihrem Unternehmen und pilotiert
              exemplarisch einen Geschäftsprozess. So können Sie ohne Risiko
              ganz unverbindlich mit minimalem Aufwand das
              Automatisierungspotenzial von RPA evaluieren.
            </p>
          </article>
          <article className={styles.offerText}>
            <h4>
              Bedarfsanalyse und Abgleich mit Leistungsangeboten von
              IT-Dienstleistern für Corona-spezifische Bedarfe
            </h4>
            <p>
              Wir analysieren Ihre kurzfristigen Bedarfe bezüglich Hard- und
              Software sowie von IT-Services, welche sich aufgrund der aktuellen
              Situation mit Corona ergeben und ermitteln geeignete
              IT-Dienstleister. Jenseits der Provider-Standardangebote
              berücksichtigen wir auch Ihre besonderen Belange und versuchen
              basierend auf unserer langjährigen Partnerschaft mit
              IT-Dienstleistern unkonventionelle Lösungen in besonderen Zeiten
              zu finden.
            </p>
          </article>
          <footer>
            <a
              href="mailto:kontakt@vscovidunited.de"
              target="_blank"
              rel="noopener noreferrer"
              className="btn-primary"
            >
              Angebot anfordern
            </a>
          </footer>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    isgBcg: file(relativePath: { eq: "showroomBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Isg
